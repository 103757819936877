import React from 'react';
import FormTwo from "../contact/FormTwo";


const AboutOne = ({sectionId}) => {
    return (
        <section className="section section-padding-equal bg-color-light" id={sectionId}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">Nosotros</span>
                                <h2 className="title mb--40">Investigamos, diseñamos &amp; desarrollamos.</h2>
                                <p>En Build IT nos dedicamos a explorar y crear experiencias memorables a través de transformaciones digitales con un enfoque centrado en el cliente.</p>
                                <p>El corazón de nuestra misión se encuentra en mejorar la vida de las personas y hacer crecer los negocios de forma rápida y segura.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">Pedí tu presupuesto ahora!</h3>
                           <FormTwo />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;