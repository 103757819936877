import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import {Spinner} from "react-bootstrap";

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Su mensaje ha sido enviado exitosamente.
        </Alert>
    )
}

const FormTwo = () => {
    const form = useRef();
    const recaptchaRef = useRef();
    const [result, showresult] = useState(false);
    const [loading, setLoading] = useState(false);

    const sendEmailApi = async (e) => {
        e.preventDefault();

        try {
            // Prepara los datos del formulario
            const formData = new FormData(form.current);
            const data = {
                from: formData.get('contact-name'),
                email: formData.get('contact-email'),
                subject: "Recibiste una consulta de builditagency.com",
                message: "Phone: " + formData.get('contact-phone') + ". Message: " + formData.get('contact-message'),

            };

            setLoading(true);
            const recaptchaToken = await recaptchaRef.current.executeAsync("send_contact_form");
            recaptchaRef.current.reset(); // Restablece el reCAPTCHA después de obtener el token

            // Envía los datos a tu API
            const response = await fetch('https://api-access.parkingtop.net/send-mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Recaptcha-Token': recaptchaToken,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const resultData = await response.json();
            console.log(resultData);
            showresult(true);

        } catch (error) {
            console.error(error);
            showresult(false);
        } finally {
            setLoading(false);
            form.current.reset();

            // Oculta el mensaje después de 5 segundos
            setTimeout(() => {
                showresult(false);
                setLoading(false);
            }, 5000);
        }
    }

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_w6oecfp', 'template_fxatp09', form.current, 'QaPDBxIqsnwf-HsLA')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        form.current.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);


    return (
        <form ref={form} onSubmit={sendEmailApi} className="axil-contact-form">
            <div className="form-group">
                <label>Nombre</label>
                <input type="text" className="form-control" name="contact-name" required disabled={loading}/>
            </div>
            <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" name="contact-email" required disabled={loading}/>
            </div>
            <div className="form-group">
                <label>Teléfono</label>
                <input type="tel" className="form-control" name="contact-phone" required disabled={loading}/>
            </div>
            <div className="form-group mb--40">
                <label>Contanos en que podemos ayudarte?</label>
                <textarea className="form-control" name="contact-message" rows="4" disabled={loading}></textarea>

            </div>
            <div className="form-group" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Enviando...</span>
                    </Spinner>
                ) : (
                    <button
                        type="submit"
                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                        name="submit-btn"
                        disabled={loading}
                    >
                        Solicitar un precio ahora
                    </button>
                )}
            </div>

            <ReCAPTCHA
                sitekey="6LfZghcpAAAAAHTCm5d0TqCd-ZF95J_DbSTlrURP"
                size="invisible"
                ref={recaptchaRef}
            />

            <div className="form-group">
                {result ? <Result/> : null}
            </div>

        </form>
    )
}

export default FormTwo;