import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import BannerOne from '../component/banner/BannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import AboutThree from "../component/about/AboutThree";
import FooterTwo from "../common/footer/FooterTwo";


const DigitalAgency = () => {

    return (
        <>
            <SEO title="Home"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BannerOne sectionId="index"/>
                <section id="services">
                    <div className="section section-padding-2 bg-color-dark">
                        <div className="container">
                            <SectionTitle
                                subtitle="Que podemos hacer por vos"
                                title="Servicios con los que podemos ayudarte"
                                description="Todo lo que deseas está al alcance, solo necesitas dar el primer paso. Lo que parece complicado al principio, se vuelve sencillo cuando te atreves a intentarlo."
                                textAlignment="heading-light-left"
                                textColor=""
                            />
                            <div className='row'>
                                <ServicePropOne colSize="col-xl-6 col-md-6" serviceStyle="" itemShow="4"/>
                            </div>
                        </div>
                        <ul className="list-unstyled shape-group-10">
                            <li className="shape shape-1"><img
                                src={process.env.PUBLIC_URL + "/images/others/line-9.png"}
                                alt="Circle"/></li>
                            <li className="shape shape-2"><img
                                src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"}
                                alt="Circle"/></li>
                            <li className="shape shape-3"><img
                                src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"}
                                alt="Circle"/></li>
                        </ul>
                    </div>
                </section>
                <AboutOne sectionId="about"/>
                <TestimonialOne sectionId="experience"/>
                <AboutThree sectionId="process"/>
                <CtaLayoutOne/>
                <FooterTwo parentClass=""/>
            </main>
        </>
    )
}

export default DigitalAgency;

