import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import TestimonialPropOne from './TestimonialPropOne';

const TestimonialOne = ({sectionId}) => {
    return (
        <section id={sectionId}>
        <div className="section section-padding">
            <div className="container">
                <SectionTitle 
                    subtitle="Experiencia"
                    title="Confiaron en nosotros"
                    description="Diseñadores UX/UI, desarrolladores frontend, backend y blockchain. Junto a empresas reconocidas ayudando a elevar su potencial al siguiente nivel."
                    textAlignment="heading-left"
                    textColor=""
                />
                <div className="row">
                    <TestimonialPropOne colSize="col-lg-4" itemShow="3"/>
                </div>
            </div>
            <ul className="shape-group-4 list-unstyled">
                <li className="shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
        </section>
    )
}

export default TestimonialOne;