import React from 'react';


const FooterTwo = () => {

    return (
        <footer className="footer-area footer-dark">
            <div className="container">
                <div className="footer-bottom">
                <div className="row">
                    <div className="col-md-6">
                    <div className="footer-copyright">
                        <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://builditagency.com/">Build IT Agency</a>.</span>
                    </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterTwo;