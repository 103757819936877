import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Análisis Profundo",
        para: "Comprendemos a fondo a las organizaciones, considerando su digitalización, tecnología, experiencia y necesidades."
    },
    {
        id: 2,
        title: "Propuesta Personalizada",
        para: "Creamos propuestas basadas en el análisis, incluyendo metodologías, tecnología y acuerdos colaborativos."
    },
    {
        id: 3,
        title: "Conexión Empresarial",
        para: "Conectamos con los negocios y usuarios, generando ideas para soluciones innovadoras."
    },
    {
        id: 4,
        title: "Evolución Continua",
        para: "Desarrollamos y evolucionamos productos para satisfacer las demandas digitales y cambiantes necesidades de los clientes."
    }
]




const AboutThree = ({sectionId}) => {
    return (
        <section id={sectionId} >
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Proceso"
                    title="Nuestras etapas para lograr el éxito"
                    description="Analizamos, creamos, conectamos y evolucionamos: pasos clave para transformar ideas en soluciones innovadoras que crecen con tus necesidades."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-3" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
        </section>
    )
}

export default AboutThree;